<template>
<div>

    <div class="row">
        <div class="col-4 cursor-pointer" v-for="(w, index) in widgets" :key="index">
            <q-card @click="openWidget(w.to)" outlined :style="`border: 1px solid ${w.color};border-left: 6px solid ${w.color}; height:100px`">
                <q-card-section :style="`color:${w.color};font-size:18px`">{{w.title}}</q-card-section>
            </q-card>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "Settings",
    data(){
        return{
            widgets:[
                {title:'Manage Business Category', to:'/settings/business-category', subtitle:'', color:'#cc5500'}
            ]
        }
    },
    methods:{
        openWidget(path){
            this.$router.push(path);
        }
    }
}
</script>

<style scoped>

</style>
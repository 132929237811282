import gql from "graphql-tag";

export const SUBSCRIBE_BUSINESS_CATEGORIES = gql`
subscription businessCategories{
  business_categories: o_business_categories(where: {deleted_at: {_is_null: true}}, order_by:{name: asc}){
    id
    name   
    created_at
    updated_at
    is_active
  }
}`
/*import{generate_UPDATE_RECORD} from "../graphql/mutations/Common";
  import {useMutation} from "@vue/apollo-composable"; */

/**
 * updateRecord
 * @composable
 *
 * Used to update record by id
 */

export default async function updateRecord(mutate, id, obj) {

    await new Promise(resolve => mutate({id, obj},  {update: () => {
        resolve();
    }}));
}
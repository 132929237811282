<template>
<div>

    <q-table :columns="columns" :rows="customers" class="table-header" :pagination.sync="{rowsPerPage: 100}"
             flat row-key="id" table-class="table-class">
    </q-table>

</div>
</template>

<script>
import Constants from "../constants/Constants";
import { ref } from "vue";
import {useSubscription} from "@vue/apollo-composable";
import moment from 'moment';
import {SUBSCRIBE_CUSTOMERS} from "../graphql/subscriptions/customers";
export default {
    name: "Customers",
    setup () {
        const customers = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_CUSTOMERS);
        onResult(r => customers.value = r.data.customers);
        return {customers};
    },
    data() {
        return {
            Constants, moment,
            columns: [
                { name: 'name', label: 'Name', field: row => row.name||'-', align: 'left', sortable: true },
                { name: 'email', label: 'Email', field:'email', align:'left', sortable: true },
                { name: 'mobile', label: 'Mobile', field: row => row.mobile||'-', align: 'left', sortable: true },
                { name: 'category', label: 'Category', field: row => row.Category?.name||'-', align: 'left', sortable: true },
                { name: 'website', label: 'Domain', field: row => row.website||'-', align: 'left', sortable: true },
               /* { name: 'promo_code', label: 'Promo Code', field: row => row.promo_code||'-', align: 'left', sortable: true }, */
                { name: 'created_at', label: 'Registered On', field: row => moment(row.created_at).format('DD/MM/YYYY hh:mm A'),
                  align: 'right', sortable: true },
            ],
        }
    }
}
</script>

<style scoped>

</style>
<template>
<div>

    <div class="row">
        <div class="col-2">
            <q-input outlined dense debounce="500" placeholder="Search ..." style="width: 200px" @update:model-value="onSearch">
                <template v-slot:prepend><q-icon name="search"/></template>
            </q-input>
        </div><q-space/>
        <div class="col-auto">
            <q-btn color="primary" @click="formDialog=true">Add Promo Code</q-btn>
        </div>
    </div>

    <q-table :columns="columns" :rows="promoCodes" class="table-header" :pagination.sync="{rowsPerPage: 100}"
             flat row-key="id" table-class="table-class" @row-click="openPromoCodeDetail">
        <template v-slot:body-cell-isActive="props"><q-td :props="props">
            <q-toggle @update:model-value="onChangeStatus(props.row)" v-model="props.row.is_active"/>
        </q-td></template>
      <template v-slot:body-cell-product="props"><q-td :props="props">
            {{props.row.valid_on.product_ids.map(id => productObj[id]).join(', ')}}
        </q-td></template>
        <template v-slot:body-cell-action="props"><q-td :props="props">
            <q-btn @click.stop="editPromoCode(props.row)" icon="edit" round flat size="sm" title="Edit Promo Code"/>
        </q-td></template>
    </q-table>

    <promo-code-detail v-model:dialog="promoCodeDetailDialog" v-model:id="promoCodeId" :productObj="productObj"/>
    <promo-code-form v-model:dialog="formDialog" v-model:id="promoCodeId"/>
</div>
</template>

<script>
import PromoCodeForm from "../components/PromoCodes/PromoCodeForm";
import Constants from "../constants/Constants";
import {reactive, ref} from "vue";
import {useMutation, useQuery, useResult, useSubscription} from "@vue/apollo-composable";
import {SUBSCRIBE_PROMO_CODE} from "../graphql/subscriptions/promoCode";
import moment from 'moment';
import updateRecord from "../composables/updateRecord";
import {generate_UPDATE_RECORD} from "../graphql/mutations/Common";
import PromoCodeDetail from "../components/PromoCodes/PromoCodeDetail";
import {GET_PRODUCTS} from "../graphql/queries/products";
export default {
    name: "PromoCodes",
    components: {PromoCodeDetail, PromoCodeForm},
    setup () {
        const promoCodes = ref([]);
        const filter = ref('%%');
        const {onResult } = useSubscription(
            SUBSCRIBE_PROMO_CODE,
            () => ({filter: filter.value})
        );
        onResult(r => promoCodes.value = r.data.product_promocodes);

        const productObj = ref({});
        const {onResult: onResultProducts} = useQuery(GET_PRODUCTS);
        onResultProducts(r => productObj.value = r.data.products.reduce((ac, s) => {ac[s.id] = s.name; return ac}, {}));

        const { mutate: mutatePromoCode } = useMutation(generate_UPDATE_RECORD('o_product_promocodes'));
        return {promoCodes, filter, mutatePromoCode, productObj};
    },
    data(){
        return{
            Constants,
            moment,
            columns: [
                { name: 'title', label: 'Title', field:'title', align:'left', sortable: true },
                { name: 'promo_code', label: 'Promo Code', field:'promo_code', align: 'left', sortable: true },
                { name: 'product', label: 'Product', field: 'products', align: 'left', sortable: true },
                { name: 'start_date', label: 'Start Date', field: r => moment(r.start_date).format('DD/MM/YYYY'),
                    align: 'right', sortable: true },
                { name: 'end_date', label: 'End Date', field: r => moment(r.end_date).format('DD/MM/YYYY'),
                    align: 'right', sortable: true },
                { name: 'status', label: 'Status', field: r => this.getPromoCodeStatus(r), align: 'left', sortable: true },
                { name: 'isActive', label: 'Is Active', field:'is_active', align:'right'},
                { name: 'action', label: 'Action', field:'action', align:'right' },
            ],
            formDialog: false,
            promoCodeDetailDialog: false,
            promoCodeId: null,
            row: {},
           /* actions: [{label:'Edit', command: () => this.editPromoCode()}], */
        }
    },
    methods:{
        getPromoCodeStatus({start_date, end_date}){
            if(moment().format('YYYY-MM-DD')>moment(end_date).format('YYYY-MM-DD')) return 'Expired';
            else if(moment().format('YYYY-MM-DD')>=moment(start_date).format('YYYY-MM-DD')) return 'Ongoing';
            else return 'Incoming';
        },
        onSearch(val) {
            this.filter = val?('%'+val+'%'):'%%';
        },
        onChangeStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'inactive':'active'} promo code ?`,
            }).onOk(() => this.updateStatus(row)).onCancel(() => row.is_active = !row.is_active)
        },
        async updateStatus(row){
            await updateRecord(this.mutatePromoCode, row.id, {is_active: row.is_active});
            this.$q.notify('Promo Code Status updated successfully');
        },
        editPromoCode(row){
            this.promoCodeId = row.id;
            this.formDialog = true;
        },
        openPromoCodeDetail(evt, row){
            this.promoCodeId = row.id;
            this.promoCodeDetailDialog = true;
        }
    }
}
</script>

<style scoped>

</style>
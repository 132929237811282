import gql from "graphql-tag";

export const SUBSCRIBE_CUSTOMERS = gql`
subscription customers{
  customers: organizations{
    id
    name
    mobile
    email
    whatsapp
    website   
    created_at
    Category {id name}
  }
}`
import gql from "graphql-tag";

export const GET_PROMO_CODE_BY_ID = gql`
query getPromoCodeById($id: uuid!){
  product_promocodes: o_product_promocodes(where: { id: {_eq: $id}}){
    id
    title
    promo_code
    description 
    start_date 
    end_date 
    product_id
    is_active
    max_qty
    used_qty
    discount_type
    discount_value
    Product {id name}
    valid_on
  }
}`

export const CHECK_PROMO_CODE_EXIST = gql`
query checkPromoCodeExist($promoCode: String!){
  product_promocodes: o_product_promocodes(where: { promo_code: {_eq: $promoCode}}){
    id     
    end_date     
  }
}`
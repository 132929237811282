<template>
<div>

    <q-dialog v-model="dialog" persistent>
        <q-card style="width: 500px">
            <q-card-section>
                <div class="text-h6">Replace Business Category</div>
            </q-card-section>
            <q-separator/>

            <q-card-section class="q-my-lg">
                <q-form ref="qForm">
                    <div class="row q-col-gutter-sm">
                        <div class="col-9">
                            <q-select label="Category*" v-model="form.name" :options="businessCategories"
                                      option-label="name" option-value="id" emit-value map-options
                                      lazy-rules outlined :rules="[v => !!v || 'Please enter Category']"/>
                        </div>
                    </div>
                </q-form>
            </q-card-section>

            <q-separator/>
            <q-card-actions class="justify-end">
                <q-btn label="CANCEL" @click="closeDialog" color="grey"/>
                <q-btn label="SAVE" @click="handleSubmit" class="q-ml-lg" color="primary" :loading="formLoading"/>
            </q-card-actions>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import {useMutation} from "@vue/apollo-composable";
import {CREATE_UPDATE_BUSINESS_CATEGORY, REPLACE_CATEGORY_OF_CUSTOMER} from "../../graphql/mutations/businessCategory";
import updateRecord from "../../composables/updateRecord";
import moment from "moment";
import {generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
let form = { name:'' };
export default {
    name: "ReplaceCategoryForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup () {
        const { mutate: mReplaceCategory } = useMutation(REPLACE_CATEGORY_OF_CUSTOMER);
        const { mutate: mutateBusinessCategory } = useMutation(generate_UPDATE_RECORD('o_business_categories'));
        return { mReplaceCategory, mutateBusinessCategory }
    },
    data(){
        return{
            form: {...form},
            formLoading: false,
            businessCategories: [],
            row: {},
            action: ''
        }
    },
    methods:{
        setData(row, action, businessCategories){
            this.businessCategories = businessCategories.filter(b => b.id !== row.id && b.is_active);
            this.row = row;
            this.action = action;
        },
        handleSubmit(){
            this.$refs['qForm'].validate().then(success => {
                if(success) this.updateCategoryOfCustomers();
            })
        },
        updateCategoryOfCustomers(){
            this.formLoading = true;
            let variables = {
                oldCategory: this.row.id,
                newCategory: this.form.name,
            };
            this.mReplaceCategory(variables,  {
                update: (c) => {
                    this.formLoading = false;
                    this.$q.notify('Business Category of Customers replaced successfully');
                    this.closeDialog();
                    if(this.action==='status') this.updateStatus(this.row);
                    else if(this.action==='delete') this.deleteBusinessCategory(this.row);
                }
            })
        },
        async updateStatus(row){
            await updateRecord(this.mutateBusinessCategory, row.id, {is_active: row.is_active});
            this.$q.notify('Business Category Status updated successfully');
        },
        async deleteBusinessCategory(row){
            await updateRecord(this.mutateBusinessCategory, row.id, {deleted_at: moment()});
            this.$q.notify('Business Category deleted successfully');
        },
        closeDialog(){
            if(this.action==='status') this.row.is_active = !this.row.is_active;
            this.$refs['qForm'].resetValidation();
            this.form = {...form};
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>

</style>
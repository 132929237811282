import { render } from "./Login.vue?vue&type=template&id=552952d4&scoped=true"
import script from "./Login.vue?vue&type=script&lang=js"
export * from "./Login.vue?vue&type=script&lang=js"

import "./Login.vue?vue&type=style&index=0&id=552952d4&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-552952d4"

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QIcon,QBtn});

import gql from "graphql-tag";

export const CREATE_UPDATE_BUSINESS_CATEGORY = gql`
mutation upsertBusinessCategory($businessCategory:[o_business_categories_insert_input!]!, $updateColumns: [o_business_categories_update_column!]!) {
  insert_o_business_categories(objects: $businessCategory, on_conflict: {constraint: o_business_categories_pkey, update_columns: $updateColumns}){
    returning{id}
  }
}`

export const REPLACE_CATEGORY_OF_CUSTOMER = gql`
mutation replaceCustomerCategory($oldCategory:uuid!, $newCategory:uuid!) {
  update_organizations(where: {category: {_eq: $oldCategory}}, _set: {category: $newCategory}){
    affected_rows
  }
}`

<template>
<div>

    <q-dialog v-model="dialog"  persistent position="right">
        <q-card style="min-width: 800px;max-height: 100vh">
            <q-card-section>
                <div class="text-h6">Promo Code Form</div>
            </q-card-section>
            <q-separator/>

            <q-card-section style="max-height:80vh;overflow-y: auto">
                <q-form ref="qForm">
                    <div class="row q-col-gutter-sm">
                        <div class="col-9">
                            <q-input class="" label="Title*" v-model="form.title" lazy-rules outlined
                                     :rules="[v => !!v || 'Please enter Title']"/>
                        </div>
                        <div class="col-9 q-mb-md">
                            <q-input class="" label="Description" style="height: auto" type="textarea" outlined v-model="form.description"/>
                        </div>
                        <div class="col-9">
                            <q-input class="" label="Promo Code*" v-model="form.promo_code" lazy-rules outlined
                                     :rules="[v => !!v || 'Please enter Promo Code', v => word(v) || 'Invalid Promo Code']"/>
                        </div>

                        <div class="col-9">
                            <q-select :options="productOptions" use-input clearable emit-value label="Product*"
                                      :rules="[v => (v&&v.length) || 'Please select Product']" outlined lazy-rules
                                      multiple use-chips stack-label
                                      map-options option-label="name" option-value="id" v-model="valid_on.product_ids"/>
                        </div>

                        <div class="col-9">
                            <div>Validity*</div>
                            <div class="row q-col-gutter-x-lg">
                                <div class="col-6" >
                                    <q-input class="" outlined label="Start Date*" mask="##-##-####" v-model="startDate"
                                             :rules="[v=> !!v || 'Please select Start Date']">
                                        <template v-slot:append>
                                            <q-icon class="cursor-pointer" name="event">
                                                <q-popup-proxy ref="startDate" transition-hide="scale" transition-show="scale">
                                                    <q-date @input="() => $refs.startDate.hide()" v-model="form.start_date"/>
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                </div>
                                <div class="col-6">
                                    <q-input class="" outlined label="End Date*" mask="##-##-####" v-model="endDate"
                                             :rules="[v=> !!v || 'Please select End Date']">
                                        <template v-slot:append>
                                            <q-icon class="cursor-pointer" name="event">
                                                <q-popup-proxy ref="endDate" transition-hide="scale" transition-show="scale">
                                                    <q-date @input="() => $refs.endDate.hide()" v-model="form.end_date"/>
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                </div>
                            </div>
                        </div>

                        <div class="col-9">
                            <div class="">Price Discount*</div>
                            <q-radio label="Percentage" val="PERCENT" v-model="form.discount_type"/>
                            <q-radio label="Amount" val="AMOUNT" v-model="form.discount_type"/>
                        </div>
                        <div class="col-9" v-if="form.discount_type === 'PERCENT'">
                            <q-input type="number" class="" label="Discount Percentage*" v-model="form.discount_value" lazy-rules outlined
                                     :rules="[v => String(v).length || 'Please enter Discount Percentage', v => v>=0 || 'Invalid Percentage']"/>
                        </div>
                        <div class="col-9" v-if="form.discount_type === 'AMOUNT'">
                            <q-input type="number" class="" label="Discount Amount*" v-model="form.discount_value" lazy-rules outlined
                                     :rules="[v => String(v).length || 'Please enter Discount Amount', v => v>=0 || 'Invalid Amount']"/>
                        </div>
                        <div class="col-9">
                            <q-input type="number" class="" label="Maximum Quantity*" v-model="form.max_qty" lazy-rules outlined
                                     :rules="[v => !!v || 'Please enter Maximum Quantity', v => v>=1 || 'Invalid Quantity']"/>
                        </div>

                    </div>
                </q-form>
            </q-card-section>

            <q-separator/>
            <q-card-actions>
                <q-btn label="SAVE" @click="handleSubmit" class="q-ml-lg" color="primary" :loading="formLoading"/>
                <q-btn label="CANCEL" @click="closeDialog" color="grey"/>
            </q-card-actions>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import Constants from "../../constants/Constants";
import moment from 'moment';
import {GET_PRODUCTS} from "../../graphql/queries/products";
import { useQuery, useResult, useMutation } from '@vue/apollo-composable';
import {reactive} from 'vue';
import {CREATE_UPDATE_PROMO_CODE} from "../../graphql/mutations/promoCode";
import {CHECK_PROMO_CODE_EXIST, GET_PROMO_CODE_BY_ID} from "../../graphql/queries/promoCode";

/* let user = JSON.parse(localStorage.user); */
let vForm = {title: '', promo_code:'', description:null, discount_type:'PERCENT', discount_value:0, max_qty:1, start_date:null, end_date:null};
export default {
    name: "PromoCodeForm",
    props: ['dialog', 'id'],
    emits: ['update:dialog', 'update:id'],
    setup (props) {
        const {result} = useQuery(GET_PRODUCTS);
        const productOptions = useResult(result);

        const form = reactive(vForm);
        const valid_on = reactive({product_ids: []});
        const {onResult} = useQuery(GET_PROMO_CODE_BY_ID, () => ({id: props.id}),
            () => ({enabled: !!props.id && props.dialog, fetchPolicy:'network-only'}));
        onResult(r => {
            form.id = '';
            Object.copyExistingKeys(form , r.data.product_promocodes[0]);
            valid_on.product_ids = r.data.product_promocodes[0].valid_on.product_ids;
        });

        const { mutate: mSavePromoCode } = useMutation(CREATE_UPDATE_PROMO_CODE);

        const checkPromoCode = async (promoCode) => {
            await new Promise(resolve => {
                const {res} = useQuery(CHECK_PROMO_CODE_EXIST, {promoCode});
                res(r => resolve(r.data))
            });
        };

        return { productOptions, form, valid_on, mSavePromoCode, checkPromoCode};
    },
    data(){
        return{
            moment, Constants,
            discountTypeOptions: [{label:'Percentage', value:'PERCENT'},{label:'Amount', value:'AMOUNT'}],
            formLoading: false,
            word: (value) => /^[\w]+$/g.test(value)
        }
    },
    computed:{
        startDate: {
            get: function () { return this.form.start_date?moment(this.form.start_date).format('DD-MM-YYYY'):null },
            set: function (newValue) { if(!newValue) this.form.start_date = null },
        },
        endDate: {
            get: function () { return this.form.end_date?moment(this.form.end_date).format('DD-MM-YYYY'):null },
            set: function (newValue) { if(!newValue) this.form.end_date = null },
        },
    },
    methods:{
        handleSubmit(){
            this.$refs['qForm'].validate().then(success => {
                if(success) this.savePromoCode();
            })
        },
        savePromoCode(){
            this.formLoading = true;
            /* this.checkPromoCode('XYZ').then(response => console.log('response', response)); */
            this.form.start_date = moment(this.form.start_date).format('YYYY-MM-DD');
            this.form.end_date = moment(this.form.end_date).format('YYYY-MM-DD');
            let variables = {
                promoCode: {...this.form, max_qty:parseInt(this.form.max_qty), valid_on: this.valid_on},
                updateColumns: [...Object.keys(this.form), 'valid_on'],
            };
            this.mSavePromoCode(variables,  {
                update: (c) => {
                    this.formLoading = false;
                    this.$q.notify('Promo Code saved successfully');
                    this.closeDialog();
                }
            })
        },
        resetForm(){
            this.form.title = '';
            this.form.promo_code = '';
            this.valid_on.product_ids = [];
            this.form.description = null;
            this.form.discount_type = 'PERCENT';
            this.form.discount_value = 0;
            this.form.max_qty = 1;
            this.form.start_date = null;
            this.form.end_date = null;
            delete this.form.id;
        },
        closeDialog(){
            this.$refs['qForm'].resetValidation();
            this.resetForm();
            this.$emit('update:dialog', false);
            this.$emit('update:id', null);
        }
    }
}
</script>

<style scoped>

</style>
<template>
<div>

    <div class="row">
        <div class="col-2">
            <q-input outlined dense debounce="500" placeholder="Search ..." style="width: 200px" v-model="search">
                <template v-slot:prepend><q-icon name="search"/></template>
            </q-input>
        </div><q-space/>
        <div class="col-auto">
            <q-btn color="primary" @click="formDialog=true">Add Business Category</q-btn>
        </div>
    </div>

    <q-table :columns="columns" :rows="businessCategories" class="table-header" :pagination.sync="{rowsPerPage: 100}"
             flat row-key="id" table-class="table-class" :filter="search">
        <template v-slot:body-cell-isActive="props"><q-td :props="props">
            <q-toggle @update:model-value="onChangeStatus(props.row)" v-model="props.row.is_active"/>
        </q-td></template>
        <template v-slot:body-cell-action="props"><q-td :props="props">
            <q-btn color="primary" icon="more_vert" flat round>
                <q-menu>
                    <q-list style="min-width: 100px">
                        <q-item clickable v-close-popup @click.stop="editBusinessCategory(props.row)">
                            <q-item-section>Edit</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click.stop="onDeleteBusinessCategory(props.row)">
                            <q-item-section>Delete</q-item-section>
                        </q-item>
                    </q-list>
                </q-menu>
            </q-btn>
        </q-td></template>
    </q-table>

    <business-category-form v-model:dialog="formDialog" ref="b-form"/>
    <ReplaceCategoryForm v-model:dialog="replaceFormDialog" ref="r-form"/>
</div>
</template>

<script>
import { ref } from "vue";
import {useMutation, useSubscription} from "@vue/apollo-composable";
import {generate_UPDATE_RECORD} from "../../graphql/mutations/Common";
import Constants from "../../constants/Constants";
import moment from 'moment';
import {SUBSCRIBE_BUSINESS_CATEGORIES} from "../../graphql/subscriptions/businessCategories";
import updateRecord from "../../composables/updateRecord";
import BusinessCategoryForm from "../../components/BusinessCategory/BusinessCategoryForm";
import ReplaceCategoryForm from "../../components/BusinessCategory/ReplaceCategory";
export default {
    name: "BusinessCategory",
    components: {ReplaceCategoryForm, BusinessCategoryForm},
    setup () {
        const businessCategories = ref([]);
        const {onResult } = useSubscription(SUBSCRIBE_BUSINESS_CATEGORIES);
        onResult(r => businessCategories.value = r.data.business_categories);

        const { mutate: mutateBusinessCategory } = useMutation(generate_UPDATE_RECORD('o_business_categories'));
        return {businessCategories, mutateBusinessCategory};
    },
    data(){
        return{
            Constants, moment,
            search:'',
            columns: [
                { name: 'name', label: 'Title', field:'name', align:'left', sortable: true },
                { name: 'created_at', label: 'Created On', field: r => moment(r.created_at).format('DD MMM YYYY hh:mm A'),
                    align: 'right', sortable: true },
                { name: 'updated_at', label: 'Updated On', field: r => moment(r.updated_at).format('DD MMM YYYY hh:mm A'),
                    align: 'right', sortable: true },
                { name: 'isActive', label: 'Status', field:'is_active', align:'right'},
                { name: 'action', label: 'Action', field:'action', align:'right' },
            ],
            formDialog: false,
            replaceFormDialog: false,
        }
    },
    methods:{
        onChangeStatus(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to ${row.is_active?'inactive':'active'} business category ?`,
            }).onOk(() => this.openReplaceCategory(row, 'status')).onCancel(() => row.is_active = !row.is_active)
        },
        openReplaceCategory(row, action){
            if(row.is_active && action==='status') return this.updateStatus(row);
            this.$refs['r-form'].setData(row, action, this.businessCategories);
            this.replaceFormDialog = true;
        },
        async updateStatus(row){
            await updateRecord(this.mutateBusinessCategory, row.id, {is_active: row.is_active});
            this.$q.notify('Business Category Status updated successfully');
        },
        onDeleteBusinessCategory(row){
            this.$q.dialog({
                title: 'Confirm', cancel: true, persistent: true,
                message: `Are you sure you want to delete business category ?`,
            }).onOk(() => this.openReplaceCategory(row, 'delete')).onCancel(() => {})
        },
        editBusinessCategory(row){
            this.$refs['b-form'].editBusinessCategory(row);
            this.formDialog = true;
        },
    }
}
</script>

<style scoped>

</style>
import gql from "graphql-tag";

export const SUBSCRIBE_PROMO_CODE = gql`
subscription product_promocodes($filter: String!){
  product_promocodes: o_product_promocodes(where:{_and: [{_or: [{title: {_ilike: $filter}}, {promo_code: {_ilike: $filter}}, {Product: {name: {_ilike: $filter}}} ]}]},
                    order_by: {created_at: desc}) {
    id
    title
    promo_code
    description 
    start_date 
    end_date    
    valid_on
    is_active
    created_at
  }
}`
import { render } from "./Customers.vue?vue&type=template&id=4babdaac&scoped=true"
import script from "./Customers.vue?vue&type=script&lang=js"
export * from "./Customers.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-4babdaac"

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable});

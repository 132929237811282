<template>
<div>

    <q-dialog v-model="dialog" position="right" full-height>
        <q-card>
            <q-card-section>
                <div class="text-h6">Promo Code Details
                    <q-btn icon="close" flat round style="position: absolute; right: 4px; top: 10px" @click="closeDialog"/>
                </div>
            </q-card-section>
            <q-separator/>

            <q-card-section>
                <div class="row q-col-gutter-sm">
                    <div class="col-6 q-mb-md">
                        <div class="label">Title</div> <div class="value">{{promoCode&&promoCode.title}}</div>
                    </div>
                    <div class="col-6">
                        <div class="label">Promo Code</div> <div class="value">{{promoCode&&promoCode.promo_code}}</div>
                    </div>
                    <div class="col-6 q-mb-md">
                        <div class="label">Product</div>
                      <div class="value" v-if="promoCode">{{promoCode.valid_on.product_ids.map(id => productObj[id]).join(', ')}}</div>
                    </div>
                    <div class="col-6">
                        <div class="label">Discount Value</div> <div class="value" v-if="promoCode">
                        {{(promoCode.discount_type === 'AMOUNT'?'Rs. ': '')+ promoCode.discount_value +(promoCode.discount_type === 'PERCENT'?' %': '')}}</div>
                    </div>
                    <div class="col-6 q-mb-md">
                        <div class="label">Validity (Start Date)</div> <div class="value">{{promoCode&&moment(promoCode.start_date).format('DD-MM-YYYY')}}</div>
                    </div>
                    <div class="col-6">
                        <div class="label">Validity (End Date)</div> <div class="value">{{promoCode&&moment(promoCode.end_date).format('DD-MM-YYYY')}}</div>
                    </div>

                    <div class="col-6 q-mb-md">
                        <div class="label">Maximum Quantity</div> <div class="value">{{promoCode&&promoCode.max_qty}}</div>
                    </div>
                    <div class="col-6">
                        <div class="label">Used Quantity</div> <div class="value">{{promoCode&&promoCode.used_qty}}</div>
                    </div>
                    <div class="col-12">
                        <div class="label">Description</div> <div class="value">{{(promoCode&&promoCode.description) || '-'}}</div>
                    </div>
                </div>
            </q-card-section>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import {useQuery, useResult} from "@vue/apollo-composable";
import {GET_PROMO_CODE_BY_ID} from "../../graphql/queries/promoCode";
import moment from 'moment';
export default {
    name: "PromoCodeDetail",
    props: ['dialog', 'id', 'productObj'],
    emits: ['update:dialog', 'update:id'],
    setup (props) {
        const {result} = useQuery(GET_PROMO_CODE_BY_ID, () => ({id: props.id}),
            () => ({enabled: !!props.id && props.dialog, fetchPolicy:'network-only'}));
        const promoCode = useResult(result, null, data => data.product_promocodes[0]);
        return {promoCode};
    },
    data(){
        return{
            moment
        }
    },
    methods:{
        closeDialog(){
            this.$emit('update:dialog', false);
            this.$emit('update:id', null);
        }
    }
}
</script>

<style scoped>
.label{
    text-transform: uppercase;
    font-size: 14px;
    color: rgba(0,0,0,.5);
    margin: 0 0 4px 0;
}
.value{
    color: #000;
    font-size: 16px;
}
</style>
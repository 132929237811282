import { render } from "./PromoCodes.vue?vue&type=template&id=f2bf7dda&scoped=true"
import script from "./PromoCodes.vue?vue&type=script&lang=js"
export * from "./PromoCodes.vue?vue&type=script&lang=js"
script.render = render
script.__scopeId = "data-v-f2bf7dda"

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QSpace,QBtn,QTable,QTd,QToggle});

<template>
  <div class="aj-login-bg overflow-hidden">

    <div style="min-width: 1140px">
      <div class="row">
        <div class="col-6 offset-3" style="z-index: 999">
          <div style="margin-top: 100px">
            <div class="row items-center" style="margin-bottom: 70px;">
              <img src="../assets/images/dummyLogo.png" alt="dummyLogo">
              <h6 class="text-subtitle1 text-weight-medium text-white ml-2">Byte Card</h6>
            </div>

            <div style="margin-bottom: 50px;">
              <h3 class="text-h5 text-white" style="font-weight: 600">Login to Your Account</h3>
            </div>

            <q-form ref="qForm">
              <div class="row justify-center">
                <div class="col-12">
                  <q-input label="Email*" v-model="form.email" lazy-rules filled dark
                           :rules="[v => !!v || 'Please enter Email', v => Constants.EmailPattern.test(v) || 'Invalid Email']"/>
                  <q-input label="Password*" v-model="form.password" lazy-rules filled dark
                           :type="showPassword?'text':'password'" @keyup.enter="handleSubmit"
                           :rules="[v => !!v || 'Please enter Password', v => v.length >= 8 || 'Password must be at least 8 characters']">
                    <template v-slot:append>
                      <q-icon :name="showPassword ? 'visibility' : 'visibility_off'" class="cursor-pointer"
                              @click="showPassword = !showPassword"/>
                    </template>
                  </q-input>
<!--                  <a class="text-body2"-->
<!--                     style="text-decoration: none; color: #99ACC1; font-weight: 600; cursor: pointer">Forgot-->
<!--                    Password?</a>-->
                </div>
              </div>
              <div class="text-center q-mt-xl">
                <q-btn label="Login to Your Account" class="full-width text-capitalize q-mb-md" color="white" size="lg"
                       padding="md" style="color: #003065 !important; font-size: 14px" @click="handleSubmit"
                       :loading="formLoading"/>
              </div>
            </q-form>
          </div>
        </div>

        <!--            <div class="col-6">-->
        <!--                <img src="../assets/images/loginHand.png" alt="loginHand" class="login-img">-->
        <!--            </div>-->
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Constants from "../constants/Constants";

export default {
    name: "Login",
    data() {
        return {
            Constants,
            form: {email: '', password: ''},
            formLoading: false,
            showPassword: false,
            loading: false,
            user: {id:1, name:'R K Sharma', email:'rk.sharma@gmail.com'}
        }
    },
    methods:{
        handleSubmit() {
            this.$refs['qForm'].validate().then(success => {
                if (success) this.login();
            })
        },
        login() {
            this.formLoading = true;
            axios({method: 'POST', url: Constants.api_url + 'admin/login', data: this.form}).then(response => {
                if (response.data.status) {
                    localStorage.setItem('token', response.data.data.token);
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                    this.$emit('setUser', response.data.data);
                    this.$q.notify('Logged in successfully');
                    this.$router.push('/dashboard');
                } else {
                    this.$q.notify(response.data.message);
                }
                this.formLoading = false;
            }).catch(e => console.log('login error', e))
        },
    }
}
</script>
<style scoped>
/deep/ .q-btn--outline:before {
  border-color: #274E78;
}

.aj-login-bg {
  height: 100vh;
  display: flex;
  justify-content: center;
  background: rgb(0, 48, 101);
  background: linear-gradient(180deg, rgba(0, 48, 101, 1) 0%, rgba(188, 188, 188, 1) 100%);

}

.login-img {
  position: fixed;
  right: 0px;
  top: 5%;
}
</style>

import { render } from "./PromoCodeDetail.vue?vue&type=template&id=04390b39&scoped=true"
import script from "./PromoCodeDetail.vue?vue&type=script&lang=js"
export * from "./PromoCodeDetail.vue?vue&type=script&lang=js"

import "./PromoCodeDetail.vue?vue&type=style&index=0&id=04390b39&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-04390b39"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtn,QSeparator});

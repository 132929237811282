<template>
<div>

    <q-dialog v-model="dialog" persistent>
        <q-card style="width: 500px">
            <q-card-section>
                <div class="text-h6">{{form.id?'Edit':'Add'}} Business Category</div>
            </q-card-section>
            <q-separator/>

            <q-card-section class="q-my-lg">
                <q-form ref="qForm">
                    <div class="row q-col-gutter-sm">
                        <div class="col-9">
                            <q-input class="" label="Category*" v-model="form.name" lazy-rules outlined
                                     :rules="[v => !!v.trim() || 'Please enter Category']" maxlength="50"/>
                        </div>
                    </div>
                </q-form>
            </q-card-section>

            <q-separator/>
            <q-card-actions class="justify-end">
                <q-btn label="CANCEL" @click="closeDialog" color="grey"/>
                <q-btn label="SAVE" @click="handleSubmit" class="q-ml-lg" color="primary" :loading="formLoading"/>
            </q-card-actions>
        </q-card>
    </q-dialog>

</div>
</template>

<script>
import {useMutation} from "@vue/apollo-composable";
import {CREATE_UPDATE_BUSINESS_CATEGORY} from "../../graphql/mutations/businessCategory";
let form = { name:'' };
export default {
    name: "BusinessCategoryForm",
    props: ['dialog'],
    emits: ['update:dialog'],
    setup () {
        const { mutate: mSaveBusinessCategory } = useMutation(CREATE_UPDATE_BUSINESS_CATEGORY);
        return { mSaveBusinessCategory }
    },
    data(){
        return{
            form: {...form},
            formLoading: false,
        }
    },
    methods:{
        editBusinessCategory(row){
            Object.copyExistingKeys(this.form , row);
            this.form.id = row.id;
        },
        handleSubmit(){
            this.$refs['qForm'].validate().then(success => {
                if(success) this.saveBusinessCategory();
            })
        },
        saveBusinessCategory(){
            this.formLoading = true;
            let variables = {
                businessCategory: {...this.form},
                updateColumns: [...Object.keys(this.form)],
            };
            this.mSaveBusinessCategory(variables,  {
                update: (c) => {
                    this.formLoading = false;
                    this.$q.notify('Business Category saved successfully');
                    this.closeDialog();
                }
            })
        },
        closeDialog(){
            this.$refs['qForm'].resetValidation();
            this.form = {...form};
            this.$emit('update:dialog', false);
        }
    }
}
</script>

<style scoped>

</style>